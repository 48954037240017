import { defineStore } from "pinia"
import { startOfDay, endOfDay } from "date-fns"

export const useDateStore = defineStore('dateStore', () => {
    const timerange = ref({
        from: startOfDay(new Date()),
        to: endOfDay(new Date())
    })

    const getTimerangeFrom = computed(() => timerange.value.from)
    const getTimerangeTo = computed(() => timerange.value.to)


    const activeButton = ref('day')
    const getActiveButton = computed(() => activeButton.value)

    async function updateTimerange(from: Date, to: Date) {
        timerange.value.from = from
        timerange.value.to = to
    }

    async function updateActiveButton(activeBtn: string) {
        activeButton.value = activeBtn
    }

    return {
        timerange,
        getTimerangeFrom,
        getTimerangeTo,
        updateTimerange,
        activeButton,
        getActiveButton,
        updateActiveButton
    }
})
